<template>
  <v-card class="opentalk-list-card">
    <UiChips v-if="category.length > 0" :chips-list="categoryList" :items="category" />

    <ul class="opentalk-list">
      <li
        v-for="(feed, index) in feedList"
        :key="feed.contentsNo"
        :class="['cursor-pointer', { fixed: fixedItemLength > index }]"
        @click="moveDetail(feed.contentsNo)"
      >
        <p class="title">{{ replaceAll(feed.contents) }}</p>
        <p class="time">{{ feed.regDtMark }}</p>
      </li>
    </ul>
  </v-card>
</template>
<script>
import { isEmpty, find } from 'lodash'
import Common from '$$utils/common'
import UiChips from './UiChips.vue'
export default {
  name: 'UiOpentalkList', //'OpentalkListCard',
  components: { UiChips },
  props: {
    item: {
      type: Array,
      default: () => {
        return []
      },
      required: true
    },
    fixedItemLength: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      category: [],
      categoryList: [],
      feedList: []
    }
  },
  watch: {
    item: {
      handler: function (nVal) {
        this.feedList = nVal
        console.log('this.feedList', this.feedList)
        if (!isEmpty(this.feedList)) {
          const findObj = find(this.categoryList, { value: this.feedList[0].postSubjectCd })
          if (!isEmpty(findObj)) {
            this.category.push(findObj.value)
          }
        }
      },
      deep: true
    }
  },
  mounted() {
    this.categoryList = this.$pinia.auth.getCommonCode('ST0031') // 오픈톡 코드
  },
  methods: {
    replaceAll(val) {
      return Common.replaceSymbols(val)
    },
    moveDetail(id) {
      this.$router.push({ name: 'CO0102M02', query: { contentsNo: id } })
    }
  }
}
</script>
