<template>
  <a href="javascript:void(0);" class="event-item event-banner-item" :class="[item.class, { main: item.main }]">
    <div class="docu">
      <div class="txt">
        <p v-if="item.subTitle" v-dompurify-html="item.subTitle"></p>
        <strong v-dompurify-html="item.title" :class="{ 'mt-6': item.main && !item.subTitle }"></strong>
        <p v-dompurify-html="item.docu"></p>
        <p v-if="item.main" v-dompurify-html="item.date" class="date"></p>
      </div>
      <div v-if="item.showNotice" class="notice-box">
        <ul class="notice">
          <li>※ 투자 전 설명 청취 및 상품설명서/약관 필독</li>
          <li>※ 원금손실 발생 가능 및 투자자 귀속</li>
        </ul>
        <v-icon v-if="item.showLogo" class="logo-hana" aria-label="하나증권"></v-icon>
      </div>
    </div>
    <div class="img">
      <!-- // 250124 -->
      <img :class="{ 'mt-n2': !item.showLogo }" :src="[item.main ? item.imgUrl : item.imgIconUrl]" />
    </div>
    <div v-if="item.closed" class="event-dim">
      <span>당첨발표</span>
    </div>
  </a>
</template>
<script>
export default {
  name: 'EventItem',
  props: {
    item: {
      type: Object
    },
    index: {
      type: Number,
      default: null
    }
  }
}
</script>
